var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirmation-message", staticStyle: { width: "100%" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", "align-self": "center" } },
            [
              _c("v-textarea", {
                ref: "signatureContent",
                attrs: {
                  outlined: "",
                  label: "Signature",
                  value: _vm.msg,
                  "full-width": "",
                  readonly: "",
                  "auto-grow": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex justify-center my-8" },
        [
          _c("mew-button", {
            attrs: { "btn-size": "xlarge", title: "Copy" },
            nativeOn: {
              click: function ($event) {
                return _vm.copy.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }